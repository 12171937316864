<template>
  <div class="steps">
    <h1 v-if="title" class="box-title">{{ title }}</h1>
    <img class="back-img" :src="coverImg" alt="" />
    <div class="steps-items flex flex-a flex-jc-sa">
      <div
        class="steps-item flex flex-a"
        v-for="(item, index) in list"
        :key="index"
      >
        <div
          class="steps-item-left"
          :style="{
            marginRight: index !== list.length - 1 ? '84px' : 0,
          }"
        >
          <img v-if="item.icon" class="item-icon" :src="item.icon" />
          <div class="text-top">{{ item.top }}</div>
          <div class="text-bottom">{{ item.bottom }}</div>
        </div>
        <img
          v-if="index !== list.length - 1"
          class="arrow"
          :src="arrowIcon || require('@/assets/img/union/arror_right.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    list: {
      type: Array,
      require: true,
    },
    coverImg: String,
    arrowIcon: {
      type: String,
      require: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.steps {
  .box-title {
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    color: #1f2329;
    margin-bottom: 40px;
  }
  position: relative;
  .back-img {
    position: absolute;
    // opacity: 0.05;
    z-index: -1;
    top: 0;
    height: 100%;
    width: 100%;
  }
  height: 527px;
  // &-items {
  //   gap: 84px;
  // }
  &-item {
    &-left {
      // margin-right: 84px;
      text-align: center;
      .text-top {
        font-weight: 500;
        font-size: 18px;
        color: #444444;
        margin-bottom: 8px;
      }
      .text-bottom {
        font-weight: 500;
        font-size: 14px;
        color: #999999;
      }
      .item-icon {
        width: 132px;
        height: 132px;
        margin-bottom: 16px;
      }
    }
    .arrow {
      width: 56px;
      height: 56px;
      margin-right: 84px;
    }
  }
}
</style>
