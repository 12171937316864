<template>
  <!-- star -->

  <div class="kejipage_wrap">
    <nav2></nav2>
    <banner2
      bannerH="蓝友联盟"
      bannerP="专业有保障、服务更贴心"
      :bannerImg="require('@/assets/img/top_back.png')"
    ></banner2>
    <div class="main">
      <!-- 工地管家 -->
      <div class="steward">
        <h1 class="box-title">各大工种</h1>
        <div class="steward-right">
          <div class="steward-right-text">
            <div class="steward-right-text-title">
              {{ `狸小住 ( 师傅端 )` }}
            </div>
            <div class="steward-right-text-content">
              APP是一个针对于工人师傅服务领域的接单平台，为全国的师傅提供装修、生活服务类目的自装、安装、维修、验房售后服务订单。在这里，师傅可以自由接单，发布案例，学习技能，赚取收入......
            </div>
          </div>
          <img
            class="right-img"
            src="@/assets/img/union/cross_back_right.png"
            alt=""
          />
        </div>
        <div class="steward-left">
          <div class="steward-left-text">
            <div class="steward-left-text-title">
              {{ `狸小住 ( 师傅端 )` }}
            </div>
            <div class="steward-left-text-content">
              海是订单、一单一结、随时提现
            </div>
          </div>
          <div class="steward-left-qrcode">
            <img src="@/assets/img/union/qrcode.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 平台优势 -->
      <div class="advantage">
        <div class="box-title">平台优势</div>
        <div class="box-item flex flex-wrap">
          <div v-for="(item, index) in advantage" :key="index">
            <div
              class="box-top"
              :class="[index % 2 ? 'box-top-blue' : 'box-top-orange']"
            >
              <img
                :src="
                  require(`@/assets/img/union/advantage_icon_${index + 1}.png`)
                "
                alt=""
              />
              <p class="advantage-top-text">{{ item.title }}</p>
            </div>
            <div
              class="box-bottom flex flex-jc flex-a"
              :class="[index % 2 ? 'box-bottom-blue' : 'box-bottom-orange']"
            >
              <div class="advantage-bottom-text">
                {{ item.bottomText }}
              </div>
            </div>
          </div>
        </div>
        <p class="advantage-top-text"></p>
      </div>
      <!-- 宣传 -->
      <BottomSteps
        class="steps"
        :list="publicizeList"
        title="宣传"
        :coverImg="require('@/assets/img/蒙版.png')"
      ></BottomSteps>
    </div>
    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import BottomSteps from "@/components/BottomSteps.vue";
export default {
  name: "union",
  components: {
    footer2,
    nav2,
    banner2,
    BottomSteps,
  },
  data() {
    return {
      arrList: [
        {
          url: require("../assets/img/keji/人脸识别.png"),
          p: "水水水水搜索",
          span: "人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。",
        },
        {
          url: require("../assets/img/keji/人脸识别.png"),
          p: "水水水水搜索",
          span: "人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。",
        },
        {
          url: require("../assets/img/keji/人脸识别.png"),
          p: "水水水水搜索打打",
          span: "人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。",
        },
      ],
      advantage: [
        {
          title: "全国海量订单师傅接单无忧",
          bottomText: "2021年618期间，订单突破150W，海量订单覆盖全国。",
        },
        {
          title: "商业保险理赔保障师傅利益",
          bottomText:
            "单单买保险，为师傅和客户人身安全保驾护航，助力师傅无忧服务。",
        },
        {
          title: "AI智能匹配实现门口接单",
          bottomText:
            "基于大数据和AI自动匹配，周边订单及时推送，实现家门口接单。",
        },
        {
          title: "双重接单模式师傅自由选择",
          bottomText:
            "平台订单分为一口价订单接单流程和公开报价订单接单流程，多种接单模式，让师傅自由选择。",
        },
        {
          title: "多类课程培训提升服务技能",
          bottomText:
            "精选各类课程，通过线上线下培训，从网络到现实，多方位提升服务技能。",
        },
        {
          title: "专业客服团队帮师傅协调问题",
          bottomText:
            "专职师傅客服团队在线实时跟踪订单，主动协调，全程解决师傅问题。",
        },
        {
          title: "平台受益透明所见即所得",
          bottomText:
            "目前平台对师傅受益透明，即师傅看到的订单价格，就是实际到手价，收益透明。",
        },
        {
          title: "接单权益多收益不用愁",
          bottomText:
            "入驻师傅多样式推单体系，降低师傅接单成本，提升师傅订輔楨单收益。",
        },
      ],
      publicizeList: [
        {
          top: "下载、入驻",
          bottom: "注册账号，入驻加盟",
        },
        {
          top: "接单、上门",
          bottom: "自由接单、上门服务",
        },
        {
          top: "报价、验收",
          bottom: "出具报价，节点验收",
        },
        {
          top: "结算",
          bottom: "服务完成、收入到账",
        },
      ],
    };
  },

  mounted() {
    this.publicizeList.forEach((_, index) => {
      this.$set(
        this.publicizeList[index],
        "icon",
        require(`@/assets/img/union/publicize_icon_${index + 1}.png`)
      );
    });
  },
};
</script>
<style scoped lang="scss">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
.main {
  width: 1200px;
  margin: 0 auto;
  .box-title {
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    color: #1f2329;
    margin-bottom: 40px;
  }
  .steward {
    margin: 40px 0;
    position: relative;
    height: 810px;
    .steward-right {
      padding: 40px;
      position: absolute;
      width: 1000px;
      height: 520px;
      right: 0;
      background: #7fa9ff;
      border-radius: 16px;
      &-text {
        color: #ffffff;
        width: 380px;
        &-title {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 24px;
        }
        &-content {
          white-space: wrap;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .right-img {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
      }
    }
    .steward-left {
      z-index: 1;
      position: absolute;
      width: 720px;
      height: 504px;
      background: #5e81f4;
      border-radius: 16px;
      // top: 306px;
      bottom: 0;
      left: 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        color: white;
        font-weight: bold;
        &-title {
          font-size: 24px;
          margin-bottom: 12px;
        }
        &-content {
          font-size: 20px;
        }
        margin-top: 103px;
        margin-bottom: 14px;
      }
      &-qrcode {
        width: 206px;
        height: 206px;
        padding: 16px;
        border-radius: 16px;
        border: 2px solid #ffffff;
        img {
          height: 174px;
          width: 174px;
        }
      }
    }
  }

  .advantage {
    margin-bottom: 120px;
    .box-item {
      column-gap: 20px;
      row-gap: 40px;
    }
    .box-top {
      width: 590px;
      height: 170px;
      border-radius: 16px 16px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-orange {
        background: #fa7e23;
      }
      &-blue {
        background: #5e81f4;
      }
    }
    .box-bottom {
      width: 590px;
      height: 170px;
      border-radius: 0px 0px 16px 16px;
      &-orange {
        background: #f3d8bc;
      }
      &-blue {
        background: #ced9fc;
      }
    }
    img {
      margin-right: 16px;
    }
    &-top-text {
      width: 144px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      white-space: wrap;
    }
    &-bottom-text {
      white-space: wrap;
      width: 168px;
      font-weight: 500;
      font-size: 14px;
      color: #444444;
      text-align: center;
    }
  }
  /deep/ .steps {
    .back-img {
      opacity: 0.05;
    }
  }
  .publicize {
    position: relative;
    .back-img {
      position: absolute;
      opacity: 0.05;
      top: 0;
      height: 100%;
      width: 100%;
    }
    height: 527px;
    &-items {
      gap: 84px;
    }
    &-item {
      &-left {
        margin-right: 84px;
        text-align: center;
        .text-top {
          font-weight: 500;
          font-size: 18px;
          color: #444444;
          margin-bottom: 8px;
        }
        .text-bottom {
          font-weight: 500;
          font-size: 14px;
          color: #999999;
        }
        .item-icon {
          width: 132px;
          height: 132px;
          margin-bottom: 16px;
        }
      }
      .arrow {
        width: 56px;
        height: 56px;
      }
    }
  }
}
</style>
